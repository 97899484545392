import { DateUtils } from '@wyz/utils';
import * as React from 'react';
import {
  DateField,
  DateRangeField,
  SelectField,
  TypeaheadField,
} from '../../index';
import { PrivateFilterDataSource } from '../types';

const getFilterComponent = (): Record<
  string,
  React.FC<PrivateFilterDataSource>
> => ({
  LIST: (props) => {
    if (props.type !== 'LIST') {
      throw new Error();
    }
    return (
      <SelectField {...props} withCheckboxes placeholder={props.placeholder} />
    );
  },
  TYPEAHEAD: (props) => {
    if (props.type !== 'TYPEAHEAD') {
      throw new Error();
    }
    return <TypeaheadField {...props} isMulti={props.isMulti} />;
  },
  DATE: (props) => {
    if (props.type !== 'DATE') {
      return <></>;
    }
    return (
      <DateField
        {...props}
        dateFormat={DateUtils.DATE_FORMAT.FULL_DATE}
        placeholderText={props.placeholder}
        showTimeSelect={false}
      />
    );
  },
  DATE_RANGE: (props) => {
    if (props.type !== 'DATE_RANGE') {
      return <></>;
    }
    return (
      <DateRangeField
        {...props}
        dateFormat={DateUtils.DATE_FORMAT.FULL_DATE}
        placeholderText={props.placeholder ?? 'dd-MM-yyyy'}
        showTimeSelect={false}
      />
    );
  },
});

export default getFilterComponent;
