import clsx from 'clsx';
import { get } from 'lodash-es';
import { Tooltip } from '../index';

import * as React from 'react';

import { CellParams, ColumnDefinition, RowModel } from './types';

export const TableCell = <TDataItem extends RowModel>({
  dataItem,
  dataItemIndex,
  column,
  expandability,
}: React.PropsWithChildren<
  {
    dataItem: TDataItem;
    dataItemIndex: number;
    column: ColumnDefinition<TDataItem>;
  } & Pick<CellParams<TDataItem>, 'expandability'>
>) => {
  const params: CellParams<TDataItem> = {
    row: dataItem,
    rowIndex: dataItemIndex,
    field: column.field,
    getValue: (field) => get(dataItem, field),
    value: get(dataItem, column.field),
    expandability,
  };
  const tdContent = (
    <div>
      {column.renderCell
        ? column.renderCell(params)
        : get(dataItem, column.field)}
    </div>
  );
  return (
    <td
      className={clsx('table-cell text-truncate', column.tdClassName, {
        'w-100': column.flex,
        'text-center': column.align === 'center',
        'text-right': column.align === 'right',
        'text-left': column.align === 'left',
      })}
      {...(column.width && {
        style: {
          minWidth: column.width,
          maxWidth: column.width,
          width: column.width,
        },
      })}
    >
      {column.description?.(params) ? (
        <Tooltip label={column.description?.(params)}>
          <div>{tdContent}</div>
        </Tooltip>
      ) : (
        tdContent
      )}
    </td>
  );
};
