import * as React from 'react';

export const ClassicDisplayIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    fill='none'
    height='141'
    viewBox='0 0 124 141'
    width='124'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    ref={ref}
  >
    <g stroke='#004f9f' strokeWidth='5'>
      <rect height='136' rx='7.5' width='119' x='2.5' y='2.5' />
      <rect height='28' rx='7.5' width='28' x='10.5' y='26.5' />
      <rect fill='#004f9f' height='9' rx='4.5' width='103' x='10.5' y='8.5' />
      <rect height='28' rx='7.5' width='28' x='48.5' y='26.5' />
      <rect height='28' rx='7.5' width='28' x='85.5' y='26.5' />
      <rect height='28' rx='7.5' width='28' x='10.5' y='63.5' />
      <rect height='28' rx='7.5' width='28' x='48.5' y='63.5' />
      <rect height='28' rx='7.5' width='28' x='85.5' y='63.5' />
      <rect height='28' rx='7.5' width='28' x='10.5' y='100.5' />
      <rect height='28' rx='7.5' width='28' x='48.5' y='100.5' />
      <rect height='28' rx='7.5' width='28' x='85.5' y='100.5' />
    </g>
    <path
      d='m101.061 42.0607c.585-.5858.585-1.5356 0-2.1214l-9.5463-9.5459c-.5858-.5858-1.5355-.5858-2.1213 0s-.5858 1.5355 0 2.1213l8.4853 8.4853-8.4853 8.4853c-.5858.5858-.5858 1.5355 0 2.1213s1.5355.5858 2.1213 0zm-83.061.4393h82v-3h-82z'
      fill='#004f9f'
    />
  </svg>
);
