export const NextDayDeliveryIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    width='55'
    height='32'
    viewBox='0 0 55 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    ref={ref}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.0469 5.47656C16.712 5.49699 20.6562 8.77404 21.9502 13.2675L12.0469 16.2717V5.47656Z'
      fill='currentColor'
    />
    <path
      d='M22.2884 30.1801V27.0289H23.0696C23.3393 27.0289 23.566 27.0674 23.7496 27.1444C23.9333 27.2214 24.0809 27.3323 24.1925 27.477C24.3041 27.6217 24.3844 27.7957 24.4332 27.9987C24.482 28.2018 24.5064 28.4294 24.5064 28.6815C24.5064 28.957 24.4715 29.1904 24.4018 29.3818C24.332 29.5732 24.239 29.7285 24.1228 29.8475C24.0065 29.9665 23.874 30.0517 23.7252 30.1031C23.5764 30.1545 23.423 30.1801 23.2649 30.1801H22.2884ZM21.1934 26.1045V31.1045H23.3416C23.7229 31.1045 24.0542 31.0403 24.3355 30.9119C24.6169 30.7835 24.8517 30.6073 25.04 30.3832C25.2283 30.1591 25.369 29.893 25.462 29.5849C25.555 29.2768 25.6015 28.9406 25.6015 28.5765C25.6015 28.161 25.5445 27.7992 25.4306 27.4911C25.3167 27.1829 25.1586 26.9262 24.9563 26.7207C24.754 26.5153 24.5146 26.3613 24.2379 26.2586C23.9612 26.1558 23.6625 26.1045 23.3416 26.1045H21.1934Z'
      fill='currentColor'
    />
    <path
      d='M26.3613 26.1045V31.1045H30.1417V30.1801H27.4564V28.9546H29.8697V28.1003H27.4564V27.0289H30.0859V26.1045H26.3613Z'
      fill='currentColor'
    />
    <path
      d='M30.8828 26.1045V31.1045H34.4051V30.1801H31.9779V26.1045H30.8828Z'
      fill='currentColor'
    />
    <path
      d='M36.1126 26.1045H35.0176V31.1045H36.1126V26.1045Z'
      fill='currentColor'
    />
    <path
      d='M39.3906 31.1045L41.0437 26.1045H39.9137L38.7908 29.6199H38.7769L37.6679 26.1045H36.5449L38.1561 31.1045H39.3906Z'
      fill='currentColor'
    />
    <path
      d='M41.4707 26.1045V31.1045H45.2511V30.1801H42.5658V28.9546H44.9791V28.1003H42.5658V27.0289H45.1953V26.1045H41.4707Z'
      fill='currentColor'
    />
    <path
      d='M47.0824 28.3664V26.9588H48.282C48.5332 26.9588 48.7215 27.0137 48.847 27.1234C48.9726 27.2331 49.0353 27.4093 49.0353 27.6521C49.0353 27.9042 48.9726 28.0863 48.847 28.1983C48.7215 28.3104 48.5332 28.3664 48.282 28.3664H47.0824ZM45.9873 26.1045V31.1045H47.0824V29.1507H48.1774C48.4518 29.1507 48.6494 29.2114 48.7703 29.3328C48.8912 29.4542 48.9702 29.6456 49.0074 29.907C49.0353 30.1077 49.0562 30.3178 49.0702 30.5373C49.0841 30.7567 49.1214 30.9458 49.1818 31.1045H50.2769C50.2257 31.0344 50.1874 30.9493 50.1618 30.8489C50.1362 30.7485 50.1176 30.6423 50.106 30.5302C50.0943 30.4182 50.0862 30.3085 50.0816 30.2011C50.0769 30.0938 50.0723 30.0004 50.0676 29.921C50.0583 29.795 50.0409 29.6689 50.0153 29.5429C49.9897 29.4168 49.949 29.3013 49.8932 29.1962C49.8374 29.0912 49.7654 29.0001 49.677 28.9231C49.5887 28.8461 49.4771 28.7889 49.3422 28.7515V28.7376C49.6212 28.6255 49.8223 28.4621 49.9455 28.2473C50.0688 28.0326 50.1304 27.7782 50.1304 27.484C50.1304 27.2926 50.0967 27.1141 50.0292 26.9483C49.9618 26.7826 49.8642 26.6367 49.7363 26.5107C49.6084 26.3846 49.455 26.2854 49.276 26.213C49.0969 26.1407 48.8958 26.1045 48.6726 26.1045H45.9873Z'
      fill='currentColor'
    />
    <path
      d='M52.0569 29.1577V31.1045H53.152V29.1857L55.0003 26.1045H53.7797L52.6219 28.0793L51.4571 26.1045H50.2295L52.0569 29.1577Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.8286 25.5714C18.1936 25.309 18.5655 25.4954 18.8136 25.8814C19.0617 26.2674 19.0926 26.7078 18.7276 26.9701C16.8075 28.3503 14.4899 29.1574 11.9955 29.1574C5.37499 29.1574 0 23.473 0 16.4713C0 9.46963 5.37499 3.78516 11.9955 3.78516C18.6159 3.78516 23.9909 9.46963 23.9909 16.4713C23.9909 16.938 23.6179 17.2051 23.1765 17.2051C22.7352 17.2051 22.3917 16.938 22.3917 16.4713C22.3917 10.4031 17.7333 5.47646 11.9955 5.47646C6.25762 5.47646 1.59922 10.4031 1.59922 16.4713C1.59922 22.5395 6.25762 27.4661 11.9955 27.4661C14.1568 27.4661 16.165 26.7672 17.8286 25.5714Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.85469 3.43148C8.19273 3.43148 7.65527 2.8631 7.65527 2.16301C7.65527 1.46291 8.19273 0.894531 8.85469 0.894531H14.9051C15.5671 0.894531 16.1045 1.46291 16.1045 2.16301C16.1045 2.8631 15.5671 3.43148 14.9051 3.43148H8.85469Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.6283 5.93375C19.2558 5.50343 19.2837 4.83422 19.6906 4.44029C20.0975 4.04633 20.7303 4.07584 21.1028 4.50616L24.4236 8.34232C24.796 8.77264 24.7681 9.44184 24.3612 9.83577C23.9544 10.2297 23.3216 10.2002 22.9491 9.7699L19.6283 5.93375Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.3391 9.08987C17.5585 8.82768 17.9378 8.80313 18.1857 9.03507C18.4336 9.26699 18.4568 9.66814 18.2375 9.93034L12.5644 16.7126C12.3451 16.9748 11.9658 16.9994 11.7179 16.7674C11.47 16.5355 11.4467 16.1344 11.666 15.8722L17.3391 9.08987Z'
      fill='currentColor'
    />
    <path
      d='M12.0575 17.318C12.6097 17.318 13.0574 16.8446 13.0574 16.2606C13.0574 15.6766 12.6097 15.2031 12.0575 15.2031C11.5053 15.2031 11.0576 15.6766 11.0576 16.2606C11.0576 16.8446 11.5053 17.318 12.0575 17.318Z'
      fill='currentColor'
    />
    <path
      d='M14.8942 25.0566L13.2772 20.0845L12.3326 25.0566H11.1113L12.4432 18.0566H13.8177L15.3836 22.9433L16.3155 18.0566H17.5367L16.2048 25.0566H14.8942Z'
      fill='currentColor'
    />
    <path
      d='M22.46 23.7982L22.2218 25.0566H17.4687L18.8005 18.0566H23.4005L23.1622 19.2961H19.8431L19.5622 20.7826H22.609L22.3749 21.9983H19.3282L18.9878 23.7982H22.46Z'
      fill='currentColor'
    />
    <path
      d='M24.243 25.0566H22.7196L25.4132 21.4902L24.1494 18.0566H25.7153L26.4004 20.3314L27.9833 18.0566H29.4982L26.9366 21.4332L28.2855 25.0566H26.694L25.9536 22.6727L24.243 25.0566Z'
      fill='currentColor'
    />
    <path
      d='M29.6259 19.2961L29.8642 18.0566H34.9449L34.7066 19.2961H32.8301L31.7365 25.0566H30.4173L31.5109 19.2961H29.6259Z'
      fill='currentColor'
    />
    <path
      d='M42.6809 19.0967C42.8341 19.4449 42.9107 19.8217 42.9107 20.2269C42.9135 20.6322 42.8809 21.0184 42.8128 21.3857C42.6341 22.3165 42.3164 23.1048 41.8597 23.7507C41.2412 24.6213 40.4583 25.0566 39.5108 25.0566H36.8087L38.1406 18.0566H40.8427C41.2285 18.063 41.5419 18.1136 41.7831 18.2086C42.1916 18.3701 42.4909 18.6661 42.6809 19.0967ZM41.4597 21.4854C41.5958 20.7731 41.6001 20.2269 41.4724 19.847C41.3448 19.4639 40.9831 19.2724 40.3874 19.2724H39.1789L38.3108 23.8409H39.5193C40.1377 23.8409 40.6342 23.5006 41.0086 22.8199C41.2129 22.4463 41.3632 22.0015 41.4597 21.4854Z'
      fill='currentColor'
    />
    <path
      d='M47.7149 18.0566L48.6042 25.0566H47.183L47.0511 23.6177H44.7405L44.0341 25.0566H42.6639L46.2341 18.0566H47.7149ZM46.6553 19.657L45.3234 22.4115H46.9149L46.6553 19.657Z'
      fill='currentColor'
    />
    <path
      d='M53.5148 18.0566H54.9998L52.068 22.4257L51.5701 25.0566H50.2595L50.7574 22.4257L49.4212 18.0566H50.9659L51.685 21.1055L53.5148 18.0566Z'
      fill='currentColor'
    />
  </svg>
);
