import { get, isObject } from 'lodash-es';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type {
  ActionMeta,
  GroupBase,
  MultiValue,
  OnChangeValue,
  SingleValue,
} from 'react-select';

import type { IOptionItem } from '@wyz/types';
import {
  FormFeedback,
  FormGroup,
  Icon,
  Label,
  Select,
  SelectProps,
  Text,
  Tooltip,
  useConfiguredForm,
} from '../index';
import clsx from 'clsx';

export type SelectFieldProps<
  IsMulti extends boolean,
  Option extends IOptionItem<number | string> = IOptionItem<number | string>,
  Group extends GroupBase<Option> = GroupBase<Option>,
  TKeys extends string = string,
> = Pick<
  SelectProps<IsMulti, Option, Group>,
  | 'isClearable'
  | 'isSearchable'
  | 'isLoading'
  | 'required'
  | 'id'
  | 'options'
  | 'isMulti'
  | 'isDisabled'
  | 'placeholder'
  | 'size'
  | 'withCheckboxes'
  | 'wrapItems'
  | 'maxShown'
  | 'isOptionDisabled'
> & {
  name: string;
  helperText?: string;
  label?: React.ReactNode;
  defaultValue?: string | Array<string>;
  Trans: React.ComponentType<{
    i18nKey: TKeys;
    values?: Record<string, string>;
  }>;
  formGroupClassName?: string;
  feedbackType?: 'tooltip' | 'inline';

  /**
   * Only used to notify the parent about change, NOT to control the component
   */
  onSelect?: (args: OnChangeValue<Option, IsMulti>) => void;
  all?: {
    enabled?: boolean;
    allOnEmpty?: boolean;
    label?: string;
  };
  tooltips?: React.ReactNode;
};

const flattenOptions = <T extends IOptionItem<string | number>>(
  options: Array<T | GroupBase<T>>,
) => {
  const flatten: Array<T> = [];
  options.forEach((option) => {
    if ('options' in option) {
      flatten.push(...option.options);
    } else {
      flatten.push(option);
    }
  });
  return flatten;
};
const ALL_VALUE = 'ALL';

function SelectField<
  IsMulti extends boolean,
  Option extends IOptionItem<string | number> = IOptionItem<string | number>,
  Group extends GroupBase<Option> = GroupBase<Option>,
  TKeys extends string = string,
>({
  Trans,
  onSelect,
  all,
  tooltips,
  formGroupClassName = 'mb-2',
  feedbackType = 'inline',
  ...props
}: SelectFieldProps<IsMulti, Option, Group, TKeys>) {
  const originalOptions = props.options;
  const {
    formState: { errors, defaultValues },
    control,
  } = useFormContext();

  const getOptionValue = (option: Option) => option.value;

  const hasSelectedAll = (meta: ActionMeta<Option>): boolean => {
    return meta.action === 'select-option' && meta.option?.value === ALL_VALUE;
  };
  const hasUnselectedAll = (meta: ActionMeta<Option>): boolean => {
    return (
      meta.action === 'deselect-option' && meta.option?.value === ALL_VALUE
    );
  };

  const configurationContext = useConfiguredForm();
  const getValue = (
    selectedOption: SingleValue<Option> | MultiValue<Option> | null,
  ): Array<string | number> | string | number | null => {
    if (
      !Array.isArray(selectedOption) &&
      isObject(selectedOption) &&
      'value' in selectedOption
    ) {
      return getOptionValue(selectedOption);
    } else if (Array.isArray(selectedOption)) {
      return selectedOption?.map((item) => item.value);
    } else {
      return null;
    }
  };
  const names = props.name.split('.');
  const errorMessage = (get(errors, [...names, 'message']) ||
    get(errors, [names[0], 'message'])) as TKeys;
  const flatOptions = flattenOptions(originalOptions);
  const optionDefaultValue = props.isMulti
    ? (flatOptions.filter((option) =>
        get(defaultValues, props.name)?.includes(getOptionValue(option)),
      ) as MultiValue<Option>)
    : (flatOptions.find(
        (option) => getOptionValue(option) === get(defaultValues, props.name),
      ) as SingleValue<Option>);

  const options: typeof originalOptions =
    all?.enabled && all.label
      ? [
          {
            label: all.label,
            value: ALL_VALUE,
          } as (typeof originalOptions)[0],
        ]
      : [];
  options.push(...originalOptions);
  const DEFAULT_PLACEHOLDER_KEY =
    'app.common.datatable.filter.select.placeholder' as TKeys;

  return (
    <FormGroup className={formGroupClassName}>
      {props.label && (
        <div className='space-x-2 position-relative'>
          {tooltips && (
            <div className='position-absolute'>
              <Tooltip placement='right' label={tooltips}>
                <Icon icon='info-circle' width={13} height={13} />
              </Tooltip>
            </div>
          )}
          <Label
            htmlFor={props.id || props.name}
            className={clsx({
              'ms-3': tooltips,
              'required': props.required,
            })}
          >
            {props.label}
          </Label>
        </div>
      )}
      <Controller
        name={props.name}
        control={control}
        render={({ field }) => {
          const currentValue = field.value;
          const value = Array.isArray(currentValue)
            ? flatOptions.filter((item) => currentValue.includes(item.value))
            : flatOptions.find((item) => item.value === currentValue);

          if (configurationContext.mode === 'READ_ONLY') {
            return props.isLoading ? (
              <div>
                <Icon icon='spinner' size='xs' />
              </div>
            ) : (
              <Text heading='normal' weight='semi-bold'>
                {Array.isArray(value)
                  ? value.map((item) => item.label).join(',') || '-'
                  : value?.label || '-'}
              </Text>
            );
          }
          return (
            <>
              <Select<IsMulti, Option, Group>
                {...props}
                placeholder={
                  props.placeholder ?? (
                    <Trans i18nKey={DEFAULT_PLACEHOLDER_KEY} />
                  )
                }
                defaultValue={optionDefaultValue}
                value={value ?? null}
                options={options}
                onChange={(selectedOption, actionMeta) => {
                  const allEnabled = all?.enabled && props.isMulti;
                  if (allEnabled && hasSelectedAll(actionMeta)) {
                    field.onChange(getValue(flatOptions as MultiValue<Option>));
                    onSelect?.(
                      flatOptions as unknown as OnChangeValue<Option, IsMulti>,
                    );
                  } else if (allEnabled && hasUnselectedAll(actionMeta)) {
                    field.onChange(getValue([]));
                    onSelect?.([] as unknown as OnChangeValue<Option, IsMulti>);
                  } else {
                    field.onChange(getValue(selectedOption));
                    onSelect?.(selectedOption);
                  }
                }}
                isOptionSelected={(option, selectValue) => {
                  if (getOptionValue(option) === ALL_VALUE) {
                    return selectValue.length === flatOptions.length;
                  }
                  const selectedValues = selectValue.map((o) => o.value);
                  return selectedValues.includes(getOptionValue(option));
                }}
                isError={!!errorMessage}
                data-cy={props.name}
              />
            </>
          );
        }}
      />
      {errorMessage ? (
        <FormFeedback invalid tooltip={feedbackType === 'tooltip'}>
          <Trans i18nKey={errorMessage} />
        </FormFeedback>
      ) : props.helperText ? (
        <FormFeedback>{props.helperText}</FormFeedback>
      ) : null}
    </FormGroup>
  );
}

export default React.memo(SelectField) as typeof SelectField;
