import * as React from 'react';

export const ColumnDisplayIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    width='123'
    height='142'
    viewBox='0 0 123 142'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    ref={ref}
  >
    <rect
      x='2.5'
      y='2.5'
      width='118'
      height='137'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='9.5'
      y='8.5'
      width='103'
      height='9'
      rx='4.5'
      fill='#004F9F'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='17.25'
      y='27.25'
      width='20.5'
      height='4.5'
      rx='2.25'
      fill='#004F9F'
      stroke='#004F9F'
      strokeWidth='4.5'
    />
    <rect
      x='51.25'
      y='27.25'
      width='21.5'
      height='4.5'
      rx='2.25'
      fill='#004F9F'
      stroke='#004F9F'
      strokeWidth='4.5'
    />
    <rect
      x='87.25'
      y='27.25'
      width='22.5'
      height='4.5'
      rx='2.25'
      fill='#004F9F'
      stroke='#004F9F'
      strokeWidth='4.5'
    />
    <rect
      x='9.5'
      y='41.5'
      width='28'
      height='26'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='9.5'
      y='75.5'
      width='28'
      height='24'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='9.5'
      y='108.5'
      width='28'
      height='25'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='47.5'
      y='41.5'
      width='28'
      height='26'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='47.5'
      y='75.5'
      width='28'
      height='24'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='47.5'
      y='108.5'
      width='28'
      height='25'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='84.5'
      y='41.5'
      width='28'
      height='26'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='84.5'
      y='75.5'
      width='28'
      height='24'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <rect
      x='84.5'
      y='108.5'
      width='28'
      height='25'
      rx='7.5'
      stroke='#004F9F'
      strokeWidth='5'
    />
    <path
      d='M22.9393 131.061C23.5251 131.646 24.4749 131.646 25.0607 131.061L34.6066 121.515C35.1924 120.929 35.1924 119.979 34.6066 119.393C34.0208 118.808 33.0711 118.808 32.4853 119.393L24 127.879L15.5147 119.393C14.9289 118.808 13.9792 118.808 13.3934 119.393C12.8076 119.979 12.8076 120.929 13.3934 121.515L22.9393 131.061ZM22.5 49L22.5 130L25.5 130L25.5 49L22.5 49Z'
      fill='#004F9F'
    />
    <path
      d='M59.9393 131.061C60.5251 131.646 61.4749 131.646 62.0607 131.061L71.6066 121.515C72.1924 120.929 72.1924 119.979 71.6066 119.393C71.0208 118.808 70.0711 118.808 69.4853 119.393L61 127.879L52.5147 119.393C51.9289 118.808 50.9792 118.808 50.3934 119.393C49.8076 119.979 49.8076 120.929 50.3934 121.515L59.9393 131.061ZM59.5 49L59.5 130L62.5 130L62.5 49L59.5 49Z'
      fill='#004F9F'
    />
    <path
      d='M96.9393 131.061C97.5251 131.646 98.4749 131.646 99.0607 131.061L108.607 121.515C109.192 120.929 109.192 119.979 108.607 119.393C108.021 118.808 107.071 118.808 106.485 119.393L98 127.879L89.5147 119.393C88.9289 118.808 87.9792 118.808 87.3934 119.393C86.8076 119.979 86.8076 120.929 87.3934 121.515L96.9393 131.061ZM96.5 49L96.5 130L99.5 130L99.5 49L96.5 49Z'
      fill='#004F9F'
    />
  </svg>
);
