/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from '@wyz/types';
import { FullPageSpinner } from '@wyz/ui';
import * as React from 'react';
import { I18nextProvider, TransProps, useTranslation } from 'react-i18next';
import { I18n } from '~/common/helpers';
import { TranslationKeys } from '~/common/types';
import { Trans as BaseTrans } from 'react-i18next/TransWithoutContext';

export function I18nProvider(props: React.PropsWithChildren) {
  const [initialized, setInitialized] = React.useState(false);
  const instance = I18n.getInstance();
  React.useLayoutEffect(() => {
    I18n.init()
      .then(() => {
        setInitialized(true);
      })
      .catch(() => {});
  }, []);

  if (!initialized) {
    return <FullPageSpinner />;
  }

  return <I18nextProvider i18n={instance}>{props.children}</I18nextProvider>;
}

type TranslationReturn = Omit<
  ReturnType<typeof useTranslation<'common'>>,
  't'
> & {
  t: TFunction<TranslationKeys>;
};

export const useI18n = (): TranslationReturn => {
  return useTranslation(['common']);
};

export const Trans = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: Omit<TransProps<any>, 't' | 'i18nKey'> & {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    i18nKey: keyof TranslationKeys;
  },
) => {
  const { t } = useI18n();
  // @ts-expect-error todo TS internal bug : Expression produces a union type that is too complex to represent.
  return <BaseTrans {...props} t={t as any} />;
};
