import * as React from 'react';
import { Form } from '../form';
import clsx from 'clsx';
import './form-feedback.scss';
import { get, isNil } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { FormTranslateFn } from '@wyz/types';
type DivNativeProps = React.HTMLAttributes<HTMLDivElement>;
type LabelNativeProps = React.ComponentProps<typeof Form.Label>;

export const FormGroup = ({
  children,
  ...rest
}: React.PropsWithChildren<DivNativeProps>) => {
  return (
    <Form.Group
      {...rest}
      className={
        !isNil(rest.className)
          ? clsx(rest.className, 'position-relative')
          : 'mb-2 position-relative'
      }
    >
      {children}
    </Form.Group>
  );
};

export const Label = ({
  children,
  ...rest
}: React.PropsWithChildren<LabelNativeProps>) => {
  return <Form.Label {...rest}>{children}</Form.Label>;
};

export const FormFeedbackField = <TKeys extends string>({
  name,
  translate,
  ...rest
}: React.PropsWithChildren<{
  name: string;
  tooltip?: boolean;
  translate: FormTranslateFn<TKeys>;
}>) => {
  const methods = useFormContext();
  const errorMessage =
    (get(methods.formState.errors, [...name.split('.'), 'message']) as TKeys) ||
    (get(methods.formState.errors, [
      ...name.split('.'),
      'root',
      'message',
    ]) as TKeys);
  return errorMessage ? (
    <FormFeedback {...rest} invalid>
      {translate(errorMessage)}
    </FormFeedback>
  ) : (
    <></>
  );
};
export const FormFeedback = ({
  children,
  invalid,
  tooltip = false,
}: React.PropsWithChildren<{
  invalid?: boolean;
  tooltip?: boolean;
}>) => {
  return (
    <Form.Control.Feedback
      type={invalid ? 'invalid' : undefined}
      style={{ display: 'block' }}
      tooltip={tooltip}
    >
      {children}
    </Form.Control.Feedback>
  );
};

const LineLoader = (props: { height?: number; width?: number }) => (
  <svg
    role='img'
    width='100%'
    height='100%'
    aria-labelledby='loading-aria'
    viewBox='0 0 400 160'
    preserveAspectRatio='none'
    {...props}
  >
    <title id='loading-aria' translate='no'>
      Loading...
    </title>
    <rect
      x='0'
      y='0'
      width='100%'
      height='100%'
      clipPath='url(#clip-path)'
      style={{ fill: "url('#fill')" }}
    ></rect>
    <defs>
      <clipPath id='clip-path'>
        <rect x='0' y='0' rx='3' ry='3' width='100%' height='100%' />
      </clipPath>
      <linearGradient id='fill'>
        <stop offset='0.599964' stopColor='#f3f3f3' stopOpacity='1'>
          <animate
            attributeName='offset'
            values='-2; -2; 1'
            keyTimes='0; 0.25; 1'
            dur='2s'
            repeatCount='indefinite'
          ></animate>
        </stop>
        <stop offset='1.59996' stopColor='#ecebeb' stopOpacity='1'>
          <animate
            attributeName='offset'
            values='-1; -1; 2'
            keyTimes='0; 0.25; 1'
            dur='2s'
            repeatCount='indefinite'
          ></animate>
        </stop>
        <stop offset='2.59996' stopColor='#f3f3f3' stopOpacity='1'>
          <animate
            attributeName='offset'
            values='0; 0; 3'
            keyTimes='0; 0.25; 1'
            dur='2s'
            repeatCount='indefinite'
          ></animate>
        </stop>
      </linearGradient>
    </defs>
  </svg>
);
type SelectLoadingIndicatorProps = {
  label?: React.ReactNode;
};

export const SelectLoadingIndicator: React.FC<SelectLoadingIndicatorProps> = (
  props,
) => (
  <FormGroup>
    {props.label && <Label>{props.label}</Label>}
    <LineLoader height={36} />
  </FormGroup>
);

type SelectErrorIndicatorProps = {
  label?: React.ReactNode;
  message: React.ReactNode;
};
export const SelectErrorIndicator: React.FC<SelectErrorIndicatorProps> = (
  props,
) => (
  <FormGroup>
    {props.label && <Label>{props.label}</Label>}
    <FormFeedback invalid>{props.message}</FormFeedback>
  </FormGroup>
);
