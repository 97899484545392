export const TodayDeliveryIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={53}
    height={28}
    fill='none'
    {...props}
    ref={ref}
  >
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M10.362 4.521c4.013.018 7.405 2.87 8.518 6.778l-8.518 2.614V4.52Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='m13.961 16.314-1.069 4.881h1.385l1.07-4.88h1.875l.245-1.108h-5.223l-.246 1.107h1.963ZM17.204 18.688c0 .855.241 1.509.723 1.962.482.453 1.167.68 2.056.68.549 0 1.035-.1 1.459-.298.424-.199.78-.469 1.07-.81.288-.34.509-.735.66-1.182.153-.448.229-.917.229-1.41 0-.419-.066-.788-.198-1.106a2.212 2.212 0 0 0-.556-.805 2.321 2.321 0 0 0-.877-.491 3.796 3.796 0 0 0-1.148-.163c-.526 0-1 .097-1.424.293-.424.196-.783.46-1.078.793a3.528 3.528 0 0 0-.68 1.153 4.038 4.038 0 0 0-.236 1.384Zm1.376 0c0-.286.041-.578.123-.877.082-.3.206-.57.372-.813.167-.244.38-.442.64-.596.26-.154.566-.23.916-.23.257 0 .473.041.649.125a1.2 1.2 0 0 1 .43.336c.11.14.19.303.24.49.05.188.075.387.075.6 0 .285-.043.576-.127.872a2.704 2.704 0 0 1-.382.81c-.17.243-.38.441-.63.595-.252.154-.547.23-.886.23-.251 0-.466-.041-.644-.125a1.21 1.21 0 0 1-.438-.34 1.443 1.443 0 0 1-.255-.49 2.028 2.028 0 0 1-.083-.587ZM25.286 20.088l.841-3.774h1.034c.246 0 .454.035.627.105a1.094 1.094 0 0 1 .666.755c.05.176.075.373.075.59 0 .32-.049.619-.145.898a2.22 2.22 0 0 1-.42.738 1.96 1.96 0 0 1-.684.504 2.241 2.241 0 0 1-.934.184h-1.06Zm-.299-4.881-1.314 5.988h2.612c.602 0 1.129-.092 1.582-.277.453-.184.83-.44 1.13-.767.301-.327.528-.714.68-1.162.152-.447.228-.93.228-1.45 0-.744-.223-1.319-.667-1.724-.444-.405-1.107-.608-1.99-.608h-2.26ZM33.217 15.207l-3.69 5.988h1.447l.788-1.333h2.34l.202 1.333h1.367l-1.043-5.988h-1.41Zm-.911 3.673 1.35-2.381h.017l.307 2.381h-1.674ZM37.583 18.872l-.5 2.323h1.385l.482-2.264L42 15.207H40.38l-1.946 2.55-.938-2.55h-1.411l1.499 3.665Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M19.324 26.541v-3.15h.78c.27 0 .497.038.681.115.184.077.331.188.443.332.112.145.192.319.24.522.05.203.074.43.074.683 0 .275-.035.509-.105.7-.07.192-.163.347-.279.466-.116.119-.249.204-.397.255a1.4 1.4 0 0 1-.46.077h-.977Zm-1.096-4.075v5h2.149c.381 0 .713-.064.994-.193a1.98 1.98 0 0 0 .705-.529c.188-.224.328-.49.421-.798.093-.308.14-.644.14-1.008 0-.416-.057-.777-.17-1.086a2.134 2.134 0 0 0-.475-.77 1.945 1.945 0 0 0-.719-.462 2.556 2.556 0 0 0-.896-.154h-2.148ZM23.398 22.466v5h3.78v-.925h-2.685v-1.225h2.413v-.854h-2.413V23.39h2.63v-.924h-3.726ZM27.917 22.466v5h3.523v-.925h-2.428v-4.075h-1.095ZM33.15 22.466h-1.095v5h1.095v-5ZM36.428 27.466l1.653-5h-1.13l-1.123 3.515h-.014l-1.109-3.515h-1.123l1.611 5h1.235ZM38.507 22.466v5h3.78v-.925h-2.685v-1.225h2.414v-.854h-2.414V23.39h2.63v-.924h-3.725ZM44.121 24.728V23.32h1.2c.251 0 .44.055.565.165.126.11.189.286.189.528 0 .252-.063.435-.189.547-.125.112-.314.168-.565.168h-1.2Zm-1.095-2.262v5h1.095v-1.954h1.096c.274 0 .472.06.593.182.12.122.2.313.237.574.028.201.049.411.063.63.014.22.05.41.111.568h1.095a.736.736 0 0 1-.115-.256 2.21 2.21 0 0 1-.056-.318 5.479 5.479 0 0 1-.024-.33l-.014-.28a3.012 3.012 0 0 0-.052-.378 1.276 1.276 0 0 0-.122-.346.843.843 0 0 0-.551-.445v-.014c.279-.112.48-.276.603-.49.123-.215.185-.47.185-.764 0-.191-.034-.37-.101-.535a1.292 1.292 0 0 0-.293-.438 1.389 1.389 0 0 0-.46-.298 1.599 1.599 0 0 0-.604-.108h-2.686ZM49.095 25.519v1.947h1.095v-1.919l1.849-3.081h-1.22L49.66 24.44l-1.165-1.975h-1.227l1.827 3.053Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M15.335 22.002c.313-.229.633-.067.847.27.213.335.24.718-.074.946-1.652 1.201-3.645 1.903-5.79 1.903C4.622 25.121 0 20.176 0 14.085 0 7.994 4.623 3.049 10.317 3.049c5.695 0 10.318 4.945 10.318 11.036 0 .406-.321.638-.7.638-.38 0-.676-.232-.676-.638 0-5.279-4.006-9.565-8.942-9.565-4.935 0-8.941 4.286-8.941 9.565s4.006 9.565 8.941 9.565a8.495 8.495 0 0 0 5.018-1.648ZM7.617 2.741c-.57 0-1.032-.494-1.032-1.103 0-.61.462-1.104 1.032-1.104h5.204c.569 0 1.031.495 1.031 1.104 0 .609-.462 1.103-1.031 1.103H7.617Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M16.883 4.917a.966.966 0 0 1 .053-1.299.821.821 0 0 1 1.215.057l2.856 3.338a.966.966 0 0 1-.054 1.299.821.821 0 0 1-1.214-.057l-2.856-3.338ZM14.913 7.663a.493.493 0 0 1 .728-.048.58.58 0 0 1 .045.779l-4.88 5.9a.493.493 0 0 1-.728.048.58.58 0 0 1-.044-.78l4.88-5.9Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M10.37 14.822c.476 0 .86-.412.86-.92s-.384-.92-.86-.92c-.474 0-.86.412-.86.92s.386.92.86.92Z'
    />
  </svg>
);
