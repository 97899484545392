import { get } from 'lodash-es';

import { Controller, useFormContext } from 'react-hook-form';
import { GroupBase } from 'react-select';

import {
  TypeAhead,
  PublicTypeAheadFieldProps,
  FormFeedback,
  FormGroup,
  Label,
  ISelectOption,
  useConfiguredForm,
  Text,
} from '../index';

function TypeaheadField<
  IsMulti extends boolean,
  Option extends ISelectOption = ISelectOption,
  Group extends GroupBase<Option> = GroupBase<Option>,
  TKeys extends string = string,
>({
  Trans,
  defaultValue,
  onSelect,
  placeholder,
  ...props
}: PublicTypeAheadFieldProps<IsMulti, Option, Group, TKeys>) {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const configurationContext = useConfiguredForm();

  const errorMessage = get(errors, [
    ...props.name.split('.'),
    'message',
  ]) as TKeys;
  const PLACEHOLDER_KEY =
    'app.common.autocomplete.min_character_message' as TKeys;
  return (
    <FormGroup>
      {props.label && (
        <Label
          htmlFor={props.id || props.name}
          className={`${props.required ? 'required' : ''}`}
        >
          {props.label}
        </Label>
      )}
      <Controller
        name={props.name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          if (configurationContext.mode === 'READ_ONLY') {
            return (
              <Text heading='normal' weight='semi-bold'>
                {Array.isArray(field.value)
                  ? field.value.map((item) => item.label).join(',')
                  : field.value?.label || '-'}
              </Text>
            );
          }

          return (
            <>
              <TypeAhead<IsMulti, Option, Group>
                {...props}
                value={field.value}
                fetcher={props.fetcher}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                  onSelect?.(selectedOption);
                }}
                isError={!!errorMessage}
                placeholder={placeholder ?? <Trans i18nKey={PLACEHOLDER_KEY} />}
              />
            </>
          );
        }}
      />
      {errorMessage ? (
        <FormFeedback invalid>
          <Trans i18nKey={errorMessage} />
        </FormFeedback>
      ) : props.helperText ? (
        <FormFeedback>{props.helperText}</FormFeedback>
      ) : null}
    </FormGroup>
  );
}

export default TypeaheadField;
