import { get } from 'lodash-es';

import { Controller, useFormContext } from 'react-hook-form';
import {
  FormFeedback,
  FormGroup,
  Label,
  Text,
  useConfiguredForm,
} from '../index';

import { DateTimePicker, DateTimePickerProps } from '../date-time-picker';
import './date-field.scss';
import { DateFormatOptions, DateUtils } from '@wyz/utils';
type DateFieldProps<TKeys extends string> = Pick<
  DateTimePickerProps,
  | 'name'
  | 'helperText'
  | 'label'
  | 'placeholderText'
  | 'required'
  | 'id'
  | 'showTimeSelect'
  | 'minDate'
  | 'disabled'
  | 'translations'
  | 'locale'
> & {
  translate: (key: TKeys) => string;
  dateFormat?: DateFormatOptions['format'];
};

function DateField<TKeys extends string>({
  translate,
  ...props
}: DateFieldProps<TKeys>) {
  const {
    formState: { errors },
    getValues,
    control,
    register,
  } = useFormContext();
  const errorMessage = get(errors, [
    ...props.name.split('.'),
    'message',
  ]) as TKeys;
  const configurationContext = useConfiguredForm();
  return (
    <FormGroup>
      <Label
        htmlFor={props.id || props.name}
        className={`${props.required ? 'required' : ''}`}
      >
        {props.label}
      </Label>
      {configurationContext.mode === 'WRITE' ? (
        <>
          <Controller
            name={props.name}
            control={control}
            render={({ field }) => {
              return (
                <>
                  <DateTimePicker
                    {...props}
                    selected={field.value}
                    onChange={field.onChange}
                  />
                </>
              );
            }}
          />
          {errorMessage ? (
            <FormFeedback invalid>{translate(errorMessage)}</FormFeedback>
          ) : props.helperText ? (
            <FormFeedback>{props.helperText}</FormFeedback>
          ) : null}
        </>
      ) : (
        <>
          <Text heading='normal' weight='semi-bold'>
            <input type='date' {...register(props.name)} hidden />
            {getValues(props.name)
              ? DateUtils.formatTimestamp(getValues(props.name) as Date, {
                  format: props.dateFormat,
                })
              : '-'}
          </Text>
        </>
      )}
    </FormGroup>
  );
}

export default DateField;
